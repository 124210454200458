<template>
  <Card>
    <span>{{ $t('identityProof.home.header') }}</span>
    <p>
      {{
        $t('identityProof.home.content', {
          name: $store.state.common.individualUserName
        })
      }}
    </p>
    <div class="form_button">
      <el-button class="purple_button" @click="confirm" data-testid="confirm">{{
        $t('common.button.identityProof')
      }}</el-button>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card';

export default {
  name: 'pendingAction',
  components: { Card },
  props: { type: String },
  methods: {
    confirm() {
      this.$emit('clickedPendingAction', this.type);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/pendingAction.scss';
</style>
