<template>
  <div id="block">
    <div class="top">
      <div class="left">
        <div class="title">
          {{ title }} -
          <I v-if="checkFile" class="i_success">{{ $t('register.confirmYourId.pending.verification') }}</I>
          <I class="i_error" v-else>{{ $t('register.confirmYourId.pending.upload') }}</I>
        </div>
        <div class="detail">
          <div v-html="tip"></div>
          <ul v-html="lists"></ul>
        </div>
      </div>
      <div class="right">
        <el-form>
          <vUpload :limit="limit" :selfTip="true" :selfText="true" v-on:updateFileInfo="updateFileInfo"></vUpload>
        </el-form>
      </div>
    </div>
    <div class="red">
      <span
        v-if="customPendingReason || PendingReason"
        v-html="$t(`identityProof.reason`, { reason: customPendingReason ? customPendingReason : PendingReason })"
      >
      </span>
    </div>
  </div>
</template>

<script>
import vUpload from '@/components/vUpload';

export default {
  name: 'block',
  props: {
    method: String,
    limit: Number,
    PendingReason: String,
    customPendingReason: String
  },
  data() {
    return {
      title: null,
      tip: null,
      lists: null,
      checkFile: false
    };
  },
  components: { vUpload },
  mounted() {
    if (this.method == 'address') {
      this.title = this.$t('register.confirmYourId.porTitle');
      this.tip = this.$t('identityProof.popup.addressProofTitle');
      this.lists = this.$t('register.confirmYourId.porTypes');
    } else if (this.method == 'id') {
      this.title = this.$t('register.confirmYourId.poidTitle');
      this.tip = this.$t('identityProof.popup.idProofTitle');
      this.lists = this.$t('register.confirmYourId.poidTypes');
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.$emit('syncFile', fileInfo.fileList);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/uploadIdOrAddress/block.scss';
</style>
