<template>
  <div class="idPoa">
    <vDialog id="UploadIdOrAddress" ref="vDialog" :visible.sync="show">
      <div class="top">
        <div class="title">{{ $t('identityProof.home.title') }}</div>
        <div class="tip" v-html="$t('identityProof.popup.header', { name: individualUserName })"></div>
      </div>
      <div class="container">
        <Block
          :limit="6"
          :PendingReason="idPendingReason"
          :customPendingReason="idCustomPendingReason"
          method="id"
          v-if="requireIDProof || keepUploadID"
          @syncFile="syncIdFile"
        ></Block>
        <idPoaForm ref="idPoaForm" v-if="showIdForm"></idPoaForm>
      </div>
      <div class="container">
        <Block
          :limit="6"
          :PendingReason="poaPendingReason"
          :customPendingReason="poaCustomPendingReason"
          method="address"
          v-if="requireAddressProof || keepUploadPOA"
          @syncFile="syncPoaFile"
        ></Block>
      </div>
      <div class="errMsg" v-if="errorMsgTrigger">{{ $t('common.formValidation.file') }}</div>
      <div class="btn_box">
        <el-button class="purple_button" @click="onSubmit" data-testid="upload">
          {{ this.$t('common.button.upload') }}
        </el-button>
      </div>
    </vDialog>
    <vDialog id="success" :visible.sync="success">
      <img src="@/assets/images/dialog_true.png" alt />
      <p>{{ $t('dialog.identityProof.success') }}</p>
    </vDialog>
  </div>
</template>

<script>
import vDialog from '@/components/home/vDialog';
import Block from '@/components/home/UploadIdAddress/Block';
import idPoaForm from '@/components/home/UploadIdAddress/Form';
import { apiVerificationIdentityProofs, apiAttach_new_document } from '@/resource';
import { apiProcess } from '@/resource/register';

export default {
  name: 'uploadIdOrAddress',
  props: {
    visible: Boolean,
    idPendingReason: String,
    idCustomPendingReason: String,
    poaPendingReason: String,
    poaCustomPendingReason: String,
    idRecode: Number,
    poaRecode: Number
  },
  data() {
    return {
      individualUserName: this.$store.state.common.individualUserName,
      show: false,
      success: false,
      errorMsgTrigger: false,
      idFiles: [],
      poaFiles: []
    };
  },
  components: { vDialog, Block, idPoaForm },
  computed: {
    requireIDProof() {
      return this.$store.state.common.requireIDProof;
    },
    requireAddressProof() {
      return this.$store.state.common.requireAddressProof;
    },
    keepUploadID() {
      return this.$store.state.common.keepUploadID;
    },
    keepUploadPOA() {
      return this.$store.state.common.keepUploadPOA;
    },
    registerStep() {
      return this.$store.state.common.registerStep;
    },
    showIdForm() {
      return this.requireIDProof && this.idFiles.length > 0;
    }
  },
  watch: {
    show(bool) {
      this.disableVisible(bool);
    },
    visible: {
      immediate: true,
      handler(bool) {
        this.show = bool;
      }
    }
  },
  methods: {
    syncIdFile(files) {
      this.idFiles = files;
      this.$nextTick(() => this.$refs.vDialog.setFullscreen());
    },
    syncPoaFile(files) {
      this.poaFiles = files;
    },
    disableVisible(bool) {
      this.$emit('update:visible', bool);
    },
    onSubmit() {
      if (this.idFiles.length == 0 && this.poaFiles.length == 0) {
        this.errorMsgTrigger = true;
        return;
      }

      if (this.registerStep < 6) this.postApiProcess();

      if (this.requireAddressProof && this.poaFiles.length > 0) this.checkIdPoaForm(11);
      if (this.requireIDProof && this.idFiles.length > 0) this.checkIdPoaForm(12);

      if (this.poaRecode && this.poaFiles.length > 0) this.attachInsertAPi(11, this.poaRecode, this.poaFiles);
      if (this.idRecode && this.idFiles.length > 0) this.attachInsertAPi(12, this.idRecode, this.idFiles);
    },
    postApiProcess() {
      apiProcess({
        idDocFilePathList: [],
        poaDocFilePathList: [],
        step: 5
      }).then(res => {
        this.$store.commit('common/setRegisterStep', 6);
      });
    },
    checkIdPoaForm(type) {
      const idPoaFormComponent = this.$refs.idPoaForm;

      if (idPoaFormComponent) {
        idPoaFormComponent.$refs['ruleForm'].validate(valid => {
          if (!valid) return;
          else this.standardInsertApi(type, idPoaFormComponent.form);
        });
      } else this.standardInsertApi(type);
    },
    getStandardData(type, idPoaForm = null) {
      if (type === 11) {
        return {
          filePathList: [...this.poaFiles],
          fileType: type
        };
      } else if (type === 12) {
        return {
          filePathList: [...this.idFiles],
          fileType: type,
          countryId: idPoaForm.countryId,
          idType: idPoaForm.idType,
          idNumber: idPoaForm.idNumber,
          firstName: idPoaForm.firstName,
          middleName: idPoaForm.middleName,
          lastName: idPoaForm.lastName
        };
      }
    },
    standardInsertApi(type, idPoaForm = null) {
      apiVerificationIdentityProofs(this.getStandardData(type, idPoaForm)).then(resp => {
        if (resp.data.code == 0) {
          this.success = true;
          if (this.idFiles.length > 0 && this.idRecode == null) {
            this.$store.commit('common/setPendingIDProofApproval', true);
            this.$store.commit('common/setRequireIDProof', false);
            this.idFiles = [];
          }
          if (this.poaFiles.length > 0 && this.poaRecode == null) {
            this.$store.commit('common/setPendingAddressProofApproval', true);
            this.$store.commit('common/setRequireAddressProof', false);
            this.poaFiles = [];
          }
        }
        this.disableVisible(false);
      });
    },
    attachInsertAPi(type, id, files) {
      apiAttach_new_document({ documentId: id, documentType: type, docPaths: files }).then(resp => {
        if (resp.data.code == 0) {
          if (type == 11) {
            this.$store.commit('common/setKeepUploadPOA', false);
            this.$store.commit('common/setPendingAddressProofApproval', true);
            this.poaFiles = [];
          }

          if (type == 12) {
            this.$store.commit('common/setKeepUploadID', false);
            this.$store.commit('common/setPendingIDProofApproval', true);
            this.idFiles = [];
          }
        }
        this.disableVisible(false);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/uploadIdOrAddress/index.scss';
.purple_button {
  width: 150px;
}
</style>
