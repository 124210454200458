<template>
  <Card>
    <span>{{ $t('notification.pendingIdentityProof.header') }}</span>
    <i18n path="notification.pendingIdentityProof.content" tag="p">
      <template v-slot:doc>{{ doc }}</template>
      <template v-slot:doc2>{{ doc2 }}</template>
    </i18n>
    <div class="form_button">
      <router-link :to="{ name: 'depositFunds' }" class="el-button purple_button" data-testid="depositFunds">
        {{ $t('register.btn.fundnow') }}
      </router-link>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card';

export default {
  name: 'notification',
  components: { Card },
  props: { id: Boolean, poa: Boolean },
  data() {
    return {
      doc2: ''
    };
  },
  computed: {
    doc() {
      let doc;
      if (this.id && this.poa) {
        this.doc2 = this.$t('notification.pendingIdentityProof.both2');
        doc = this.$t('notification.pendingIdentityProof.both');
      } else if (this.id) {
        this.doc2 = this.$t('notification.pendingIdentityProof.single');
        doc = this.$t('notification.pendingIdentityProof.id');
      } else if (this.poa) {
        this.doc2 = this.$t('notification.pendingIdentityProof.single');
        doc = this.$t('notification.pendingIdentityProof.poa');
      }

      return doc;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/pendingAction.scss';
</style>
